import { createWebHistory, createRouter } from "vue-router";
import { AuthenticationGuard, AuthenticationProperties as auth0 } from "vue-auth0-plugin";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import(
      /* webpackChunkName: "login" */ '@/views/public/Login.vue'),
  },
  {
    path: "/",
    name: "Dashboard",
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: [AuthenticationGuard],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/site-manager",
    name: "SiteManager",
    component: () => import(
      /* webpackChunkName: "sitemanager" */ '@/views/SiteManager.vue'),
    beforeEnter: [AuthenticationGuard],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/support",
    name: "Support",
    beforeEnter(to, from, next) {
      window.location.href = "https://skynexis.com";
    }
  },
  // {
  //   path: "/device/:id",
  //   name: "Device",
  //   component: () => import(
  //     /* webpackChunkName: "device" */ '@/views/Device.vue'),
  //   beforeEnter: [AuthenticationGuard],
  //   props: true,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: "/:catchAll(.*)",
    component: () => import(
      /* webpackChunkName: "notfound" */ '@/views/NotFound.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;