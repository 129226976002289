<template>
  <div id="dropdown-list-with-icon">
    <n-input-group>
      <div>
        <n-button
          class="icon-section"
          secondary
          type="primary"
        >
          <template #icon>
            <slot />
          </template>
        </n-button>
      </div>
      <div>
        <n-select
          v-model:value="selectedValue"
          class="item-selector"
          filterable
          :disabled="disabled"
          :placeholder="placeholder"
          :options="options"
        />
      </div>
    </n-input-group>
  </div>
</template>
<script>
import { ref } from 'vue'

export default {
  name: 'DropdownListWithIcon',
  props: {
    type: {
        type: String,
        default: 'default',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: 'Please Select an Item',
    },
    default: {
      type: String,
      required: false,
      default: null,
    },
    options: {
        type: Array,
        required: false,
        default: null,
    },
  },
  emits: ['valueChanged'],
  data:() => ({
      selectedValue: ref(null),
  }),
  watch: {
      selectedValue: {
          immediate: true,
          handler: function(newVal, oldVal) {
              if (newVal != null) {
                this.$emit('valueChanged', newVal);
              }
          }
      },
      default: {
          immediate: true,
          handler: function(newVal, oldVal) {
              if (newVal != null) {
                  this.selectedValue = newVal;
              }
          }
      },
  },
}
</script>

<style lang="scss">

#dropdown-list-with-icon {

  .n-base-selection-overlay__wrapper {
    padding-left: 35px !important;
  }

  .button-active {
    color: #fff !important;
    background-color: #33A4D3 !important;
  }

  .button-inactive {
    color: #000;
    .svg {
      color: #177799;
    }
  }

  .n-button__content {
    margin-right: auto;
    padding-left: 20px;
  }
  .text-section-container {
    padding-left: 40px;
    width: 100%;
  }
}

</style>

<style lang="scss" scoped>

  .icon-section {
    z-index: 100;
    width: 44px;
    padding: 20px;
  }

  .item-selector {
    margin-left: -80px;
    z-index: 50;
    padding-top: 0px;
    padding-right: 5px;
    padding-bottom: 20px;
    padding-left: 40px;
    width: calc(100% + 80px);
  }

  .n-button {
    z-index: 1000;
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .n-button:hover {
    background-color: rgba(#CCE1E9,1);
  }

  .n-button:focus {
    background-color: #fff;
  }
</style>