import { useMessage, MessageReactive } from "naive-ui";
import * as dayjs from 'dayjs'
var currentDate = new Date();

const base = {
    data:() => ({
      message: useMessage(),
      messageReactive: null,
    }),
    methods: {
      navigateTo(route) {
        this.$router.push({ path: route});
      },
      navigateToPublicWebsite() {
        window.open(process.env.VUE_APP_PUBLIC_WEBSITE_URL, '_blank');
      },
      parseReportAndDownload(data, fileName) {
        const linkSource = `data:application/pdf;base64, ${data}`;
        const link = document.createElement('a');
        link.href = linkSource;
        link.download = `report-${fileName}-skynexis-${dayjs(currentDate).format('YYYYMMDDHHmmss')}`;
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      createLoadingMessage(text) {
        this. messageReactive = this.message.loading(text,{
          duration: 0
        });
      },
      destroyLoadingMessage() {
        if (this.messageReactive) {
          this.messageReactive.destroy();
          this.messageReactive = null;
        }
      }

    }
  };

export default base;
