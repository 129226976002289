<template>
  <n-config-provider
    :theme-overrides="themeOverrides"
    inline-theme-disabled
  >
    <n-message-provider>
      <div v-if="auth.authenticated === true">
        <navigation-bar
          :default-active-menu-item="defaultLandingPage"
          @isMenuExpanded="setMenuState"
        />
        <div :class="isMenuExpanded ? 'content-menu-extended' : 'content-menu-collapsed'">
          <router-view />
        </div>
      </div>
      <div v-else>
        <router-view />
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script>
import { ref, defineComponent } from "vue";
import NavigationBar from '@/components/NavigationBar.vue'
import DarkTheme from '@/assets/theme/theme-dark.js'
import router from 'vue-router'
import LightTheme from '@/assets/theme/theme-light.js'
import UserManagementService from '@/services/UserManagementService'
import { injectionToken , AuthenticationState } from 'vue-auth0-plugin';

import { AuthenticationProperties as auth0 } from 'vue-auth0-plugin';

export default defineComponent({
  name: 'App',
  components: {
    NavigationBar
  },
  inject: ['auth', 'injectionToken '],
  data() {
    return {
      themeOverrides: ref(LightTheme),
      isMenuExpanded: ref(false),
      defaultLandingPage: ref(null)
    }
  },
  watch: {
      ['auth.user']: {
          immediate: true,
          handler: function(value) {
              this.setLoggedInUser();
          }
      },
  },
  mounted() {
  },
  methods: {
    setMenuState(isMenuExpanded) {
      this.isMenuExpanded = isMenuExpanded;
    },
    setLoggedInUser() {
      if (this.auth.user?.email) {
        UserManagementService.findUserByEmail(this.auth.user.email).then(response => {
          this.$router.push({ path: '/'+ response.data.preferences.default_landing_page})
          this.defaultLandingPage = response.data.preferences.default_landing_page;
          this.$store.dispatch('updateLoggedInUser', response.data)
        }).catch(error => {
          console.log(error);
        });
      }
    }
  }
})
</script>

<style lang="scss">
  #app {
    font-family: Poppins, Arial, Helvetica, OpenSans, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
  }

  body {
    width: 100%;
    height: 100%;
    background-color: #E5F8FF;
  }

  ul { 
    margin: 0 !important;
  }

  .n-base-icon svg {
    color: #177799;
  }

  .n-tabs .n-tabs-rail .n-tabs-tab-wrapper .n-tabs-tab.n-tabs-tab--active {
    background-color: #177799;
    color: #fff;
  }

  .text-active {
    color: #1AD620
  }

  .text-error {
    color: #FF0000
  }

  .text-warning {
    color: #FFAC26;
  }

  .text-small {
    font-size: 1em;
  }

  .text-medium {
    line-height: 0.7em;
    font-size: 1.1em;
  }

  .text-normal {
    font-size: 1.15em;
  }

  .text-large {
    line-height: 1em;
    font-size: 1.3em;
  }

  .text-huge {
    line-height: 1.25em;
    font-size: 1.5em;
  }

  .bold {
    font-weight: bold;
    color: #000000;
    letter-spacing: 0px;
  }

  .italic {
    font-style: italic;
  }

  .inline-block {
    display: inline-block;
  }

  .block {
    display: block;
  }

  .content-card {
    z-index: auto;
    border-collapse: collapse;
    background-color: #fff;
    margin: 10px;
    border: 5px solid transparent;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .card {
    z-index: auto;
    border: 0px;
    border-collapse: collapse;
    background-color: #fff;
    margin: 10px;
    padding: 10px 10px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;

    .card-heading {
      margin: 10px;
      padding: 10px;
    }
    
    .card-content {
      z-index: auto;
      border-collapse: collapse;
      background-color: #fff;
      margin: 10px;
      border: 5px solid transparent;
      border-radius: 10px;
      -moz-border-radius: 10px;
      -webkit-border-radius: 10px;
      box-shadow: 0px 3px 6px #00000029;
    }
  }

  .card:hover {
      box-shadow: 0px 6px 12px #00000029;
  }

  .content-menu-collapsed {
    transition: margin 1s;
    margin: 80px 0px 0px 80px;
  }

  .content-menu-extended {
    transition: margin 1s;
    margin: 80px 0px 0px 210px;
  }

  // Icon Components

  // Navigation specific styling
  #navigation-bar {
    .n-base-selection__border {
      border-color: #fff !important;
    }
  } 

  // Tree component
  .n-tree {
    .n-tree-node {
      width: calc(100% - 10px);
    }
    .n-tree-node-content {
      height: 40px !important;
    }
    .n-tree-node-checkbox {
      height: 40px !important;
    }
    .n-tree-node-switcher {
      height: 40px !important;
    }
  }

</style>
