import { createStore } from 'vuex'
// import { getInstance } from "vue-auth0-plugin";

const store = createStore({
    state:{
        selectedOwningOrg: {},
        availableOwningOrgs: [],
        loggedInUser: {},
    },
    getters:{
        GET_LOGGED_IN_USER(state) {
            return state.loggedInUser;
        }
    },
    mutations:{
        UPDATE_SELECTED_OWNING_ORG(state, selectedOwningOrg) {
            state.selectedOwningOrg = selectedOwningOrg;
        },
        UPDATE_AVAILABLE_OWNING_ORGS(state, availableOwningOrgs) {
            state.availableOwningOrgs = availableOwningOrgs;
        },
        UPDATE_LOGGED_IN_USER(state, loggedInUser) {
            state.loggedInUser = loggedInUser;
        },
    },
    actions:{
        updateSelectedOwningOrg({commit}, selectedOwningOrg) {
            this.state.availableOwningOrgs.forEach(item => {
                if (item.id == selectedOwningOrg) {
                    commit('UPDATE_SELECTED_OWNING_ORG', item);
                }
            })
        },
        updateAvailableOwningOrgs({commit}, availableOwningOrgs) {
            commit('UPDATE_AVAILABLE_OWNING_ORGS', availableOwningOrgs);
        },
        updateLoggedInUser({commit}, loggedInUser) {
            commit('UPDATE_LOGGED_IN_USER', loggedInUser);
        }
    },
})

export default store;