<template>
  <n-input-group
    id="button-with-icon-seperate-and-text"
  >
    <div>
      <n-button
        class="icon-section"
        :class="activeButton ? 'button-active' : ''"
        strong
        secondary
        :size="size"
        :loading="loading"
        :type="type"
        :disabled="disabled"
      >
        <template #icon>
          <slot />
        </template>
      </n-button>
    </div>
    <div class="text-section-container">
      <n-button
        class="text-section"
        :class="activeButton ? 'button-active' : 'button-inactive'"
        strong
        secondary
        :size="size"
        :loading="loading"
        :type="type"
        :disabled="disabled"
      >
        {{ text }}
      </n-button>
    </div>
  </n-input-group>
</template>

<script>
import { defineComponent } from "vue";


export default defineComponent({
  name: 'ButtonWithIconSeperateAndText',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'small'
    },
    activeButton: {
      type: Boolean,
      default: false
    }
  },
})
</script>

<style lang="scss">

#button-with-icon-seperate-and-text {
  .button-active {
    color: #fff !important;
    background-color: #33A4D3 !important;
  }

  .button-inactive {
    color: #000;
    .svg {
      color: #177799;
    }
  }

  .n-button__content {
    margin-right: auto;
    padding-left: 20px;
  }
  .text-section-container {
    width: 100%;
  }
}

</style>

<style lang="scss" scoped>

  .n-button {
    height: 44px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .icon-section {
    z-index: 100;
    width: 44px;
    padding: 20px;
  }

  .text-section {
    margin-left: -41px;
    z-index: 50;
    padding-top: 20px;
    padding-right: 5px;
    padding-bottom: 20px;
    padding-left: 40px;
    width: calc(100% + 40px);
  }

  .n-button:hover {
    background-color: rgba(#CCE1E9,1)
  }
</style>