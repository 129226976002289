<template>
  <div id="logout-button">
    <n-popover
      placement="bottom"
      :style="{ width: '200px', height: '100px', padding: '10px 5px 15px 10px'}"
      :delay="350"
    >
      <template #trigger>
        <n-button
          class="logout-button-icon"
          strong
          secondary
          :type="type"
        >
          <template #icon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </template>
        </n-button>
      </template>
      <div>
        <div class="popover-item">
          <ButtonWithIconSeperateAndText 
            :text="'My Profile'"
            :disabled="true"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </ButtonWithIconSeperateAndText>
        </div>
        <div class="popover-item">
          <ButtonWithIconSeperateAndText 
            :text="'Logout'"
            @click="logout"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
              />
            </svg>
          </ButtonWithIconSeperateAndText>
        </div>
      </div>
    </n-popover>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import ButtonWithIconSeperateAndText from '@/components/shared/ButtonWithIconSeperateAndText.vue'

export default defineComponent({
  name: 'ButtonLogout',
    components: {
      ButtonWithIconSeperateAndText,
  },
  inject: ['auth'],
  props: {
    type: {
        type: String,
        default: 'primary',
    },
  },
  data:() => ({
  }),
  methods: {
    logout() {
      this.auth.logout({ returnTo: process.env.VUE_APP_BASE_URL + 'login'});
    },
  }
})
</script>

<style lang="scss">
 .popover {
  
 }
</style>

<style lang="scss" scoped>

  .n-button:hover {
    background-color: rgba(#CCE1E9,1)
  }

  .popover-item {
    padding: 5px 5px;
  }

  .logout-button-icon {
    width: 40px;
    height: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .logout-button-icon:hover {
    background-color: rgba(#CCE1E9,1)
  }

</style>