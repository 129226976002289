<template>
  <n-button
    id="button-with-icon"
    :class="activeButton ? 'button-active' : ''"
    strong
    secondary
    :loading="loading"
    :type="type"
    :disabled="disabled"
  >
    <template #icon>
      <slot />
    </template>
  </n-button>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: 'ButtonWithIcon',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    activeButton: {
      type: Boolean,
      default: false
    }
  },
})
</script>

<style lang="scss">

.button-active {
  color: #fff !important;
  background-color: #33A4D3 !important;
}

</style>

<style lang="scss" scoped>
  .n-button {
    width: 44px;
    height: 44px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .n-button:hover {
    background-color: rgba(#CCE1E9,1)
  }

</style>