import { createApp } from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import NaiveUI from 'naive-ui'
import VueAuth0Plugin from 'vue-auth0-plugin';

import './assets/css/style.css'

const app = createApp(App)
    .use(NaiveUI)
    .use(Vuex)
    .use(store)
    .use(VueAuth0Plugin, {
        domain: process.env.VUE_APP_AUTH0_DOMAIN,
        client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
        redirect_uri: process.env.VUE_APP_BASE_URL,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        // cacheLocation: 'localstorage',
        useRefreshTokens: process.env.VUE_APP_AUTH0_USE_REFRESH_TOKENS,
        scope: ['openid', 'profile', 'email'],
    })
    .use(router);

    // app.config.globalProperties.selectedOwningOrg = null;
    // app.config.globalProperties.loggedInUser = null;

    app.mount('#app');
