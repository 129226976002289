<template>
  <div id="navigation-bar">
    <div class="nav-bar-top">
      <div class="nav-bar-top-items-container">
        <div class="nav-bar-top-right-section">
          <div class="nav-bar-top-item">
            <div class="logged-in-user-email">
              <span>{{ auth.user?.name || 'NO_USER' }}</span>
            </div>
          </div>
          <div class="nav-bar-top-item">
            <ButtonLogout
              :disabled="!auth.authenticated"
              style="height: 40px; width: 40px;"
            />
          </div>
          <div class="nav-bar-top-item">
            <DropdownListWithIcon
              :placeholder="'Select a Organization'"
              :options="options"
              :default="$store.state.selectedOwningOrg.name"
              @valueChanged="updateSelectedOwningOrg"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                />
              </svg>
            </DropdownListWithIcon>
          </div>
        </div>
      </div>
    </div>
    <!-- Menu close and open button -->
    <ButtonWithIcon
      style="background-color: #fff"
      :class=" isMenuExpanded ? 'left-menu-collapse-button' : 'left-menu-expand-button'"
      @click="setMenuExpandedState"
    >
      <svg
        v-if="isMenuExpanded" 
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        stroke-width="2"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M4 6h16M4 12h16M4 18h7"
        />
      </svg>
    </ButtonWithIcon>
    <!-- Left navigation bar -->
    <div :class="isMenuExpanded ? 'nav-bar-left-expanded' : 'nav-bar-left-collapsed'">
      <img
        v-if="isMenuExpanded"
        class="nav-bar-logo-extended"
        alt="SkyNexis Logo Icon"
        src="@/assets/images/logo-full.svg"
      >

      <div
        v-else
        class="nav-bar-logo-collapsed"
      >
        <img
          alt="SkyNexis Logo Icon"
          src="@/assets/images/logo-icon.svg"
        >
      </div>

      <div class="nav-bar-left-items-container">
        <!-- Dashboard Button -->
        <div class="nav-bar-left-item">
          <n-popover
            v-if="!isMenuExpanded"
            width="200px"
            placement="right"
            :delay="hoverAnimationDelay"
            trigger="hover"
          >
            <template #trigger>
              <ButtonWithIcon
                :active-button="activeButton === 'dashboard'"
                @click="setActiveMenuItem('dashboard', '/')"
              >
                <svg
                  v-if="activeButton === 'dashboard'"
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                  <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
                </svg>
                <svg
                  v-else
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                  />
                </svg>
              </ButtonWithIcon>
            </template>
            <template #header>
              <n-text
                strong
                depth="1"
              >
                Dashboard
              </n-text>
            </template>
            For general statistics regarding your meters.
          </n-popover>
          <ButtonWithIconSeperateAndText 
            v-else
            :active-button="activeButton === 'dashboard'"
            :text="'Dashboard'"
            @click="setActiveMenuItem('dashboard', '/')"
          >
            <svg
              v-if="activeButton === 'dashboard'"
              width="1em"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
              <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
              />
            </svg>
          </ButtonWithIconSeperateAndText>
        </div>
        <div class="nav-bar-left-item">
          <!-- Site Manager Button -->
          <n-popover
            v-if="!isMenuExpanded"
            width="200px"
            placement="right"
            :delay="hoverAnimationDelay"
            trigger="hover"
          >
            <template #trigger>
              <ButtonWithIcon
                :active-button="activeButton === 'site-manager'"
                @click="setActiveMenuItem('site-manager', '/site-manager')"
              >
                <svg
                  v-if="activeButton === 'tenants'"
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  v-else
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
                  />
                </svg>
              </ButtonWithIcon>
            </template>
            <template #header>
              <n-text
                strong
                depth="1"
              >
                Site Manager
              </n-text>
            </template>
            Manage and view all sites and devices linked to your account.
          </n-popover>
          <ButtonWithIconSeperateAndText 
            v-else
            :active-button="activeButton === 'site-manager'"
            :text="'Site Manager'"
            @click="setActiveMenuItem('site-manager', '/site-manager')"
          >
            <svg
              v-if="activeButton === 'tenants'"
              width="1em"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              width="1em"
              height="1em"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              />
            </svg>
          </ButtonWithIconSeperateAndText>
        </div>
        <div class="nav-bar-left-item">
          <n-popover
            v-if="!isMenuExpanded"
            width="200px"
            placement="right"
            :delay="hoverAnimationDelay"
            trigger="hover"
          >
            <template #trigger>
              <ButtonWithIcon
                disabled
                :active-button="activeButton === 'reports'"
                @click="setActiveMenuItem('reports', '/reports')"
              >
                <svg
                  v-if="activeButton === 'reports'"
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" />
                  <path
                    fill-rule="evenodd"
                    d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                    clip-rule="evenodd"
                  />
                </svg>
                <svg
                  v-else
                  width="1em"
                  height="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
                  />
                </svg>
              </ButtonWithIcon>
            </template>
            <template #header>
              <n-text
                strong
                depth="1"
              >
                Reports
              </n-text>
            </template>
            Timely reports regarding your devices and meters.
          </n-popover>
          <ButtonWithIconSeperateAndText 
            v-else
            :disabled="true"
            :text="'Reports'"
            :active-button="activeButton === 'reports'"
          >
            <!-- @click="setActiveMenuItem('reports', '/reports')" -->
            <svg
              v-if="activeButton === 'reports'"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              class="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2h-1.528A6 6 0 004 9.528V4z" />
              <path
                fill-rule="evenodd"
                d="M8 10a4 4 0 00-3.446 6.032l-1.261 1.26a1 1 0 101.414 1.415l1.261-1.261A4 4 0 108 10zm-2 4a2 2 0 114 0 2 2 0 01-4 0z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
              />
            </svg>
          </ButtonWithIconSeperateAndText>
        </div>
        <div class="nav-bar-left-bottom-section">
          <div class="nav-bar-left-item">
            <n-popover
              v-if="!isMenuExpanded"
              width="200px"
              placement="right"
              :delay="hoverAnimationDelay"
              trigger="hover"
            >
              <template #trigger>
                <ButtonWithIcon
                  disabled
                  :active-button="activeButton === 'account'"
                  @click="setActiveMenuItem('account', '/account')"
                >
                  <svg
                    v-if="activeButton === 'account'"
                    width="1em"
                    height="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    class="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </ButtonWithIcon>
              </template>
              <template #header>
                <n-text
                  strong
                  depth="1"
                >
                  My Account
                </n-text>
              </template>
              For all account related settings.
            </n-popover>
            <ButtonWithIconSeperateAndText 
              v-else
              :disabled="true"
              :text="'Account'"
              :active-button="activeButton === 'account'"
            >
              <!-- @click="setActiveMenuItem('account', '/account')" -->
              <svg
                v-if="activeButton === 'account'"
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                  clip-rule="evenodd"
                />
              </svg>
              <svg
                v-else
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </ButtonWithIconSeperateAndText>
          </div>
          <div class="nav-bar-left-item">
            <n-popover
              v-if="!isMenuExpanded"
              width="200px"
              placement="right"
              :delay="hoverAnimationDelay"
              trigger="hover"
            >
              <template #trigger>
                <ButtonWithIcon
                  @click="navigateToPublicWebsite"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
                    />
                  </svg>
                </ButtonWithIcon>
              </template>
              <template #header>
                <n-text
                  strong
                  depth="1"
                >
                  Help & Support
                </n-text>
              </template>
              Support and assistance regarding SkyNexis.
            </n-popover>
            <ButtonWithIconSeperateAndText 
              v-else
              :text="'Support'"
              @click="navigateToPublicWebsite"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288"
                />
              </svg>
            </ButtonWithIconSeperateAndText>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapState } from 'vuex';
import OrganizationService from '@/services/OrganizationService'
import DropdownListWithIcon from '@/components/shared/DropdownListWithIcon.vue'
import ButtonWithIcon from '@/components/shared/ButtonWithIcon.vue'
import ButtonWithIconSeperateAndText from './shared/ButtonWithIconSeperateAndText'
import ButtonLogout from '@/components/shared/ButtonLogout.vue'
import BaseMixin from '@/mixins/BaseMixin.js'

export default defineComponent({
  name: 'NavigationBar',
  components: {
      DropdownListWithIcon,
      ButtonWithIcon,
      ButtonLogout,
      ButtonWithIconSeperateAndText,
  },
  mixins: [BaseMixin],
  inject: ['auth'],
  props: {
    defaultActiveMenuItem:{
      type: String,
      default: null
    }
  },
  emits: ['isMenuExpanded'],
  data:() => ({
      activeButton: ref(null),
      selectedValue: ref(null), 
      hoverAnimationDelay: ref(1000),
      options: ref([]),
      isMenuExpanded: ref(false),
      isDefaultMenuItemSet: ref(false)
  }),
  computed: mapState(['loggedInUser']),
  watch: {
      isMenuExpanded: {
          immediate: true,
          handler: function(value) {
              this.$emit('isMenuExpanded', value);
          }
      },
      defaultActiveMenuItem: {
        immediate: true,
          handler: function(value) {
            if (!this.isDefaultMenuItemSet && value !== null) {
              this.activeButton = value;
              this.isDefaultMenuItemSet = true;
            }
          }
      },
      loggedInUser: {
          handler: function() {
              this.findOrganizations();
          }
      }
  },
  mounted() {
    // this.activeButton = this.$store.loggedInUser.preferences.default_landing_page
    // this.setActiveMenuItem('dashboard', '/');
  },
  created() {
    // this.findOrganizations();
  },
  methods: {
    setMenuExpandedState() {
      this.isMenuExpanded = !this.isMenuExpanded;
    },
    setActiveMenuItem(name ,path) {
      this.activeButton = name;
      this.navigateTo(path);
    },
    updateSelectedOwningOrg(selectedOwningOrg) {
      this.$store.dispatch('updateSelectedOwningOrg', selectedOwningOrg);
    },
    findOrganizations() {
      OrganizationService.find(this.loggedInUser.id).then(response => {
        this.$store.dispatch('updateAvailableOwningOrgs', response.data);
        response.data.forEach( item => {
          this.options.push({
            label: item.name,
            value: item.id
          })
        })
        if (response.data && response.data.length) {
          this.$store.dispatch('updateSelectedOwningOrg', response.data[0].id);
        }
      }).catch(error => {
        console.log(error);
      });
    }
  },
})
</script>

<style scoped lang="scss">

#navigation-bar {
    position: absolute;
    top: 0;
}

// Left navigation bar

.nav-bar-left-collapsed {
    background: #FFFFFF;
    height: 100vh;
    width: 80px;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    transition: width 1s;
}

.nav-bar-left-expanded {
    background: #FFFFFF;
    height: 100vh;
    width: 210px;
    position: fixed;
    z-index: 1000;
    overflow: hidden;
    transition: width 1s;
}

.left-menu-expand-button {
  position: fixed;
  z-index: 999;
  padding-left: 50px;
  left: 70px;
  top: 20px;
  transition: left 1s;
}

.left-menu-collapse-button {
  position: fixed;
  z-index: 999;
  padding-left: 50px;
  left: 190px;
  top: 20px;
  transition: left 1s;
}

.nav-bar-logo-collapsed {
    text-align: center;
    height: 40px;
    width: 40px;
    padding: 23px 15px;
}

.nav-bar-logo-extended {
    text-align: center;
    height: 75px;
    width: 180px;
    padding: 5px 15px;
    margin-top: -5px;
}

.nav-bar-left-items-container {
    padding: 5px;
    height: inherit;

    .nav-bar-left-item {
        padding: 10px;

        .n-button {
            height: 44px;
            width: 44px;
        }
    }
}

.nav-bar-left-bottom-section {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 10px);
}

// Top navigation bar

.logged-in-user-email {
  color: #fff;
  position: relative;
  top: 15%;
  bottom: 25%;
}

.nav-bar-top-items-container {
    padding: 5px;
    
    .nav-bar-top-item {
        display: inline;
        padding: 10px;

        .n-button {
            height: 44px;
            width: 44px;
        }

        .n-select {
            padding: 5px;
        }
    }
}

.nav-bar-top-right-section {
    position: absolute;
    display: inline-flex;
    top: 8px;
    right: 80px
}

.nav-bar-top {
    background: #177799;
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 999;
}

</style>
