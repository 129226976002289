import axios from './BaseService.js'
const baseURL = process.env.VUE_APP_API_URL + '/user-management';

export default {
  async findUserByEmail(email) {
    var params = {
      email: email
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get(`/user/email`, { params });
  },
};