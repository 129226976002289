import axios from './BaseService.js'
const baseURL = process.env.VUE_APP_API_URL + '/management';

export default {
  async find(userId) {
    var params = {
      userId: userId
    };
    axios.defaults.baseURL = baseURL;
    return await axios.get('/organizations', { params });
  },
};