import axios from "axios";
import { AuthenticationProperties as auth0 } from 'vue-auth0-plugin';

// Create Base Axios Service with Headers
const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

// Interceptor to see the request that is made.
// Here we inject the Bearer token for a request header.
instance.interceptors.request.use(async (request) => {
  const token = await auth0.getTokenSilently();
  request.headers['Authorization'] = `Bearer ${token}`;
  // console.log('Starting Request', JSON.stringify(request))
  return request;
}, (error) => {
  return Promise.reject(error);
})

// Interceptor to see the response that was received.
instance.interceptors.response.use(response => {
  // console.log('Response:', JSON.stringify(response, null, 2))
  return response
})

export default instance;